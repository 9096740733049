import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ReactComponent as IconCheckEmpty } from "../assets/checkbox-empty.svg";
import { ReactComponent as IconCheckCorrect } from "../assets/checkbox-correct.svg";
import { ReactComponent as IconCheckWrong } from "../assets/checkbox-wrong.svg";
import { ReactComponent as IconGraphics } from "../assets/popup-graphics.svg";
import { ReactComponent as IconMute } from "../assets/mute.svg";
import { ReactComponent as IconSound } from "../assets/sound.svg";



import { register } from 'swiper/element/bundle';
register();
const answersApiUrl = `${process.env.REACT_APP_REST_API_URL}/humanrights/v1/answer`;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
        {
          rootMargin: '-49.5% -30% -49.5% -30%',
          threshold: 0,
        }),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default function CardView({ language, post, translations: t, answers, setAnswers, isMuted, setIsMuted, showStories }) {
  const cardRef = useRef(null);
  const swiperRef = useRef(null);
  const isInViewport = useIsInViewport(cardRef);
  const [isQuiz, setIsQuiz] = useState(post.type === 'quiz');
  const isAnswered = answers && answers.hasOwnProperty(post.id.toString());
  const answerIndex = isAnswered ? answers[post.id.toString()] : null;
  const [hasVideo, setHasVideo] = useState(post.items.filter(item => item.video).length > 0);
  const videoContainerRef = useRef(null);
  const [showAnswerInfo, setShowAnswer] = useState(null); // slide index or null
  const [count, setCount] = useState(8);
  const countRef = useRef(count);
  countRef.current = count;

  useEffect(() => {
    setCount(8);

    const timer = setInterval(() => {
      if (countRef.current === 0) {
        clearTimeout(timer);
        setShowAnswer(null);
      }
      setCount(countRef.current - 1);
    }, 1000);

    return () => clearTimeout(timer);

  }, [showAnswerInfo]);

  useEffect(() => {


    if (cardRef.current) {
      const videoEl = cardRef.current.querySelector('.swiper-slide-active video');
      if (videoEl && showStories) {
        videoEl.pause();
        return;
      }
      if (videoEl && isInViewport) {
        videoEl.play();
      } else if(videoEl) {
        videoEl.pause();
      }
    }

  }, [isInViewport, showStories]);

  useEffect(() => {
    // Object with parameters
    const params = {
      slidesPerView: 1,
      pagination: {
        clickable: true,
      },
      injectStyles: [
        `
            .swiper-pagination {
              margin-top: 20px;
              position: static;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .swiper-pagination-bullet {
              background: #D0D0D0;
              opacity: 1;
            }
            .swiper-pagination-lock {
              display: none;
            }
            .swiper-pagination-bullet.swiper-pagination-bullet-active {
              background: #DB349C;
            }
            `,
      ],
    };
    if (!swiperRef.current) {
      return;
    }
    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);
  /**
   *
   * @param {Event} e
   * @param {object} post
   * @param {integer} answerIndex
   */
  const setAnswer = (e, post, answerIndex) => {
    setShowAnswer(answerIndex);

    fetch(answersApiUrl, {
      method: 'POST',
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `question=${post.id}&answer=${answerIndex}`
    })
      .then((response) => response.json())
      .then((data) => {
        setAnswers(data.data.answers);
        //setPosts(data);
        //setLoading(false);
      });
  }

  useEffect(() => {
    if ( videoContainerRef.current) {
      const videoEl = videoContainerRef.current.querySelector("video");
      if (videoEl) {
        videoEl.muted = isMuted;
      }
    }
  }, [isMuted]);

  return (<div ref={cardRef} className="block w-full pb-24 my-10 overflow-hidden relative">
    {/*post.title.rendered*/} {/*isInViewport ? 'nähtav' : 'peidus'*/}
    {post?.post_author && (
    <div className='relative z-10 -mb-42 pt-10 pl-18 pr-60'>
      <p className='flex items-center'>{post?.post_author?.image && <span className='flex items-center justify-center flex-none w-32 h-32'><img className="object-cover w-32 h-32 border border-gray-300 rounded-full aspect-square" src={post.post_author.image.url} /></span>}
      <span className={`block overflow-hidden text-xs ml-7 whitespace-nowrap ${hasVideo ? `text-white` : `text-black`} font-bold mix-blend-exclusion`}>{post?.post_author?.username || ''}</span></p>
    </div>
    )}
    <swiper-container init="false" ref={swiperRef}>
      {isQuiz && (
        <swiper-slide>
        <div key="question" className={`relative ${post?.post_author ? 'pt-50' : ''}`}>
          {(post.question_image) && (<img className="object-cover aspect-square" src={post.question_image.url} />)}
        </div>
      </swiper-slide>
      )}
      {post.items.map((item, index) => { return (
        <swiper-slide>
          <div key={index} className="relative" onDoubleClick={(e) => {
            if (isQuiz) {
              setAnswer(e, post, index);
            }
           }}>

            {item.text_on_image && (<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center p-20 text-stroke">{item.text_on_image}</div>)}
            {(item.image && !item.video) && (<img className={`${post?.post_author ? 'mt-50' : ''} object-cover aspect-square`} src={item.image.url} />)}
            {item.video &&
              (<div className='relative'><div ref={videoContainerRef} onClick={() => {
                  setIsMuted(!isMuted)
                }} dangerouslySetInnerHTML={{ /*poster=${item.image.url}  */
                __html: `<video preload=metadata muted playsInline loop id="index-video" class="w-full min-h-400 bg-gray-100">
                <source src=${item.video.url} type="video/mp4"/></video>`
              }}>
              </div>
              <div className='absolute right-0 bottom-0 cursor-pointer rounded-full bg-white p-4 w-24 h-24 mr-10 mb-10 icon' onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsMuted(!isMuted)
                }}>
                {isMuted ? <IconMute/> : <IconSound/>}
              </div>
              </div>)}

            {isQuiz && (
              <div className={`absolute p-24 ${showAnswerInfo === index ? `max-w-342 w-full` : `max-w-342`} left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white text-black shadow-md text-center`}>

                {showAnswerInfo === index ?
                (<><IconGraphics className='absolute top-0 right-0 -mr-4 -mt-18' /><span className='text-sm text-black font-medium leading-[1.5em]'>{item.answer_clarification}</span></>) :
                (<span className='text-center'>{item.answer_text}</span>)}
              </div>
            )}

            {isQuiz && (
              <button onClick={(e) => setAnswer(e, post, index)} className="absolute bottom-0 left-0 flex items-center py-8 mb-20 ml-24 bg-blue-400 rounded-full max-w-280 pl-9 pr-18">
                {(isAnswered && answerIndex === index) ?
                  (item.is_correct ? <IconCheckCorrect className='flex-none w-34 h-34 mr-14' /> : <IconCheckWrong className='flex-none w-34 h-34 mr-14' />) :
                  <IconCheckEmpty className='flex-none w-34 h-34 mr-14' />}

                <span className='font-medium text-sm text-white leading-[1.25em] min-h-36 flex items-center'>
                  {isAnswered && answerIndex === index ? (item.is_correct ? t['Correct answer!'] : t['Wrong answer!']) : t['Click here or double tap on the image to choose the answer']}
                </span>
              </button>)
            }
          </div>
        </swiper-slide>
      )
      })}
    </swiper-container>
    <div dangerouslySetInnerHTML={{ __html: `${post?.post_author?.username ? `<span class="font-bold">${post.post_author.username}</span> ` : ''}${post.description}` }} className='px-12 text-sm font-normal mt-18 card-description'></div>
  </div>)
}
