import React, { useEffect, useState, useRef } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    useParams
  } from "react-router-dom";


  import { ReactComponent as IconCheck } from "../assets/check.svg";
  import { ReactComponent as IconCross } from "../assets/cross-small.svg";
  export default function HomeView({language, setLanguage, loading, posts, answers, translations : t}) {

    const answeredQuestions = posts.filter((post) => answers.hasOwnProperty(post.id.toString()));
    const questionPosts = posts.filter(post => post.type === 'quiz');
    const correctAnswers = answeredQuestions.filter(post => {
        const answer = post.items[parseInt(answers[post.id.toString()])];
        return answer.is_correct;
    })
    const percentage = correctAnswers.length / questionPosts.length * 100;

    let { hash, lang } = useParams();

    useEffect(() => {
        if (lang) {
            setLanguage(lang);
        }
    }, []);

    // 20 - täis 764 - tühi  - 744 kokku
    return (
        <div key="1" className='pb-134'>
        {loading ? 
            (<div key="loading">{t['Loading...']}</div>) :
        (<>
        <div className='relative w-252 mx-auto'>
        <svg className='transform -rotate-90' xmlns="http://www.w3.org/2000/svg" width="252" height="252" viewBox="0 0 252 252" fill="none">
        <g filter="url(#filter0_i_140_639)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M126 14C64.1441 14 14 64.1441 14 126C14 187.856 64.1441 238 126 238C187.856 238 238 187.856 238 126C238 64.1441 187.856 14 126 14ZM0 126C0 56.4121 56.4121 0 126 0C195.588 0 252 56.4121 252 126C252 195.588 195.588 252 126 252C56.4121 252 0 195.588 0 126Z" fill="#F0F0F0"/>
        </g>
        <circle cx="126" cy="126" r="119" stroke="#6100FF" stroke-width="14" fill="none" stroke-linecap="round" stroke-dasharray="764" stroke-dashoffset={744 - 744 * percentage / 100} />
        <defs>
        <filter id="filter0_i_140_639" x="0" y="0" width="252" height="254" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_140_639"/>
        </filter>
        </defs>
        </svg>
            <div className='absolute left-0 top-1/2 transform -translate-y-1/2 text-center w-full'>
            <div className='text-xl font-bold'>{correctAnswers.length} / {questionPosts.length}</div>
            <div className='text-base font-normal'>{t['correct answers']}</div>
            </div>
        </div>
        <ul key="posts">
            {answeredQuestions.map((post) => {
                const answer = post.items[parseInt(answers[post.id.toString()])];

                return (<li key={post.id} className='mt-28 px-14'>
                <p className='flex items-center text-sm font-semibold'>{post.question}
                <span className='ml-auto pl-20'>{answer.is_correct ? <IconCheck/> : <IconCross/>}</span></p>
                <p className='mt-10 text-sm font-normal'>{answer.answer_text}</p>
                <p className='mt-6 text-xs'>{answer.answer_clarification}</p>
            </li>)}
            )}
        </ul>
        </>)}
        </div>
    );
  }
  