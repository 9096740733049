import './App.css';
import React, { useEffect, useState } from 'react';
import {
  HashRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useLocation
} from "react-router-dom";
import CardView from './components/CardView';
import ResultsView from './components/ResultsView';

// icons
import { ReactComponent as IconLogo } from "./assets/logo.svg";
import { ReactComponent as IconArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as IconBurger } from "./assets/burger.svg";
import { ReactComponent as IconCross } from "./assets/cross.svg";
import { ReactComponent as IconCrossSmall } from "./assets/cross-small.svg";
import { ReactComponent as IconHash } from "./assets/hash.svg";
import { ReactComponent as IconHashSmall } from "./assets/hash-small.svg";
import { ReactComponent as IconHome } from "./assets/home.svg";
import { ReactComponent as IconVideos } from "./assets/videos.svg";

import {
  EmailShareButton,
  FacebookShareButton,
  // FacebookMessengerShareButton,
  LinkedinShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  // FacebookMessengerIcon,
  LinkedinIcon,
} from "react-share";

// API URLs
const answersApiUrl = `${process.env.REACT_APP_REST_API_URL}/humanrights/v1/answers`;
const postsUrl = `${process.env.REACT_APP_REST_API_URL}/humanrights/v1/stream`;

const AnimatedRoutes = ({ params, language, setLanguage, answers, loading, setAnswers, setHash, stories, posts, hash, translations: t, showVideos, setShowVideos, isMuted, setIsMuted, showStories, setShowStories }) => {
  const location = useLocation();

  return (

    <Routes location={location} key={location.pathname}>
      <Route path="/:lang?" element={<CardView language={language} setLanguage={setLanguage} loading={loading} posts={posts} stories={stories} answers={answers} setAnswers={setAnswers} hash={hash} setHash={setHash} translations={t} showVideos={showVideos} setShowVideos={setShowVideos} showStories={showStories} setShowStories={setShowStories} isMuted={isMuted} setIsMuted={setIsMuted} />} />
      <Route path="/:lang/hash/:hash" element={<CardView language={language} setLanguage={setLanguage} loading={loading} posts={posts} stories={stories} answers={answers} setAnswers={setAnswers} hash={hash} setHash={setHash} translations={t} showVideos={showVideos} setShowVideos={setShowVideos} showStories={showStories} setShowStories={setShowStories} isMuted={isMuted} setIsMuted={setIsMuted} />} />
      <Route path="/:lang/results" element={<ResultsView language={language} setLanguage={setLanguage} posts={posts} loading={loading} answers={answers} translations={t} isMuted={isMuted} setIsMuted={setIsMuted} />} />
    </Routes>
  );
};

function App() {
  const [language, setLanguage] = useState('et');
  const [languages, setLanguages] = useState([]);
  const [answers, setAnswers] = useState({});
  const [categories, setCategories] = useState([]);
  const [stories, setStories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [showHashModal, setShowHashModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showStories, setShowStories] = useState(false);
  const [hash, setHash] = useState(null); // active hash filter
  const [showVideos, setShowVideos] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const hideModals = () => {
    setShowHashModal(false);
    setShowStories(false);
    setShowVideos(false);
    setShowMenuModal(false);
  }

  useEffect(() => {
    setLoading(true);

    fetch(postsUrl + '?lang=' + language)
      .then((response) => response.json())
      .then((data) => {
        setTranslations(data.translations || {});
        setLanguages(data.languages || []);
        setPosts(data.posts || []);
        setCategories(data.categories || []);
        setStories(data.stories || []);
        setLoading(false);
      });

    fetch(answersApiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAnswers(data.data.answers);
      });

    /*fetch(categoriesApiUrl)
        .then((response) => response.json())
        .then((data) => {
          setCategories(data);
        });*/
  }, [language]);

  useEffect(() => {
    document.body.classList.toggle('modal-open', showStories);
  }, [showStories]);

  return (
    <div className="App">
      <HashRouter basename={`/`}>
        <header>
          <div className={`fixed left-0 top-0 right-0 flex items-center px-14 py-10 ${showMenuModal ? 'pointer-events-none' : 'bg-white'} z-30`}>
            <button onClick={() => { hideModals(); setShowMenuModal(!showMenuModal); }} className='flex items-center pointer-events-auto logo'>
              <IconLogo /><span className='ml-8 md:hidden'><IconArrowDown /></span>
            </button>
            <button className={`${showMenuModal ? 'hidden' : ''} ml-auto text-blue-400 text-md font-semibold`} onClick={() => setShowHashModal(!showHashModal)}>
              {hash ? <span className='flex items-center'>#{hash}</span> : <IconHash />}
            </button>
            {hash && <Link to="/" className='ml-14'><IconCrossSmall /></Link>}
          </div>

          {showHashModal && (<div className='z-30 py-20 modal-content md:bottom-0 md:ml-244 px-14'>
            <nav className='max-w-390 md:mx-auto'>
              <ul className='inline-flex flex-col gap-18'>
                {categories.map((category) => (
                  <li>
                    <Link to={`/${language}/hash/${category.slug}`} onClick={(e) => { e.stopPropagation(); setShowHashModal(false); }} className="ml-auto font-semibold text-md">#{category.name}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>)}
        </header>

        <main className="flex pt-50">
          <div className={`${showMenuModal ? 'flex' : 'hidden'} flex-col right-0 md:right-auto bg-white md:flex z-20 fixed left-0 top-0 bottom-0 flex-none md:w-244 px-24 pb-20 md:pt-60 md:border-r md:border-gray-150`}>
            <button className='absolute top-0 right-0 mt-10 mr-14 md:hidden' onClick={() => setShowMenuModal(false)}><IconCross /></button>
            <nav className={`pt-60 md:pt-0 ${loading ? 'visibility-hidden': ''}`}>
              <ul className='inline-flex flex-col nav-main gap-18'>
                <li>
                  <NavLink to={`/${language}`} exact onClick={e => hideModals()} className='flex text-sm font-semibold'><IconHome className='flex-none mr-8' />{translations['Home']}</NavLink>
                </li>
                <li>
                  <Link to={`/${language}`} onClick={(e) => { e.stopPropagation(); hideModals(); setShowVideos(true); }} className="flex text-sm font-semibold"><IconVideos className='mr-8' />{translations['Videos']}</Link>
                </li>
                <li>
                  <button onClick={(e) => { e.stopPropagation(); hideModals(); setShowHashModal(true); }} className="flex text-sm font-semibold"><IconHashSmall className='mr-8' />{translations['Topics']}</button>
                </li>
                <li>
                  <NavLink to={`/${language}/results`} onClick={() => hideModals()} className="flex text-sm font-semibold"><IconBurger className='mr-8' />{translations['Results']}</NavLink>
                </li>
              </ul>
            </nav>
            <nav className='pt-32 mt-auto pb-60 md:pb-0'>
              <div className="flex justify-center py-20 text-black md:justify-start">
                <FacebookShareButton className="mr-8" subject="Cybergram" url="https://cybergram.humanrights.ee">
                  <span className="block border border-black rounded-full">
                    <FacebookIcon iconFillColor="black" bgStyle={{fill:"#ffffff"}} size={30} round={true} />
                  </span>
                </FacebookShareButton>

                {/* <FacebookMessengerShareButton className="mr-8" subject="Cybergram" url="https://cybergram.humanrights.ee">
                  <span className="block border border-black rounded-full">
                    <FacebookMessengerIcon iconFillColor="black" bgStyle={{fill:"#ffffff"}} size={30} round={true} />
                  </span>
                </FacebookMessengerShareButton> */}

                <LinkedinShareButton className="mr-8" subject="Cybergram" url="https://cybergram.humanrights.ee">
                  <span className="block border border-black rounded-full">
                    <LinkedinIcon iconFillColor="black" bgStyle={{fill:"#ffffff"}} size={30} round={true} />
                  </span>
                </LinkedinShareButton>

                <EmailShareButton subject="Cybergram" url="https://cybergram.humanrights.ee">
                  <span className="block border border-black rounded-full">
                    <EmailIcon iconFillColor="black" bgStyle={{fill:"#ffffff"}} size={30} round={true} />
                  </span>
                </EmailShareButton>
              </div>
              <ul className='flex justify-center'>
                {languages.map((lang, index) => (
                <li key={index} className={index > 0 ? 'px-8 border-l border-black' : 'pr-8'}>
                  <NavLink to={`/${lang.slug}`} className={`text-sm leading-4 ${language === lang.slug ? 'font-bold' : 'font-semibold'}`} onClick={() => {setShowMenuModal(false); setLanguage(lang.slug)}}>{lang.name}</NavLink>
                </li>))}
              </ul>
              {translations?.['nav_logo'] && <img className='w-full mx-auto mt-10 max-w-200' src={translations.nav_logo.url} />}
            </nav>
          </div>
          <div className='flex justify-center flex-grow md:pl-244 md:pr-20 lg:pr-114'>
           {!loading && <AnimatedRoutes language={language} setLanguage={(lang) => {     
              if (languages.filter(l => l.slug === lang).length > 0) {
                setLanguage(lang);
              } 
            }}  posts={posts} stories={stories} loading={loading} answers={answers} setAnswers={setAnswers} hash={hash} setHash={setHash} translations={translations} showVideos={showVideos} setShowVideos={setShowVideos} showStories={showStories} setShowStories={setShowStories} isMuted={isMuted} setIsMuted={setIsMuted} />}
           {loading && <div className='text-center'>{translations?.['Loading...'] || 'Loading...'}</div>}
          </div>
        </main>

        <footer className="fixed bottom-0 left-0 right-0 z-20 bg-white border-t border-gray-100 md:hidden">
          <nav>
            <ul className='flex items-start h-full py-12 nav-footer h-50 px-18'>
              <li className="flex justify-start w-1/3"><NavLink to={`/${language}`} onClick={e => hideModals()}><IconHome /></NavLink></li>
              <li className="flex justify-center w-1/3"><Link to={`/${language}`} onClick={(e) => { e.stopPropagation(); setShowVideos(true); }}><IconVideos className='mx-auto' /></Link></li>
              <li className="flex justify-end w-1/3"><NavLink to={`/${language}/results`} className='flex flex-col'><span  className='flex items-center justify-end w-24 h-24 ml-auto'><IconBurger /></span></NavLink></li>
            </ul>
          </nav>
        </footer>
      </HashRouter>
    </div>
  );
}

export default App;
