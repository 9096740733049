import React, { useEffect, useState, useRef } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    useParams
  } from "react-router-dom";
  import Card from './Card';
  import Stories from './Stories';

  export default function CardView({setLanguage, posts, stories, loading, translations : t, answers, setAnswers, hash : activeHash, setHash, showVideos, setShowVideos, showStories, setShowStories, isMuted, setIsMuted}) {
    let { hash, lang } = useParams();
    const location = useLocation();

    useEffect(() => {
        setHash(hash ? hash : null);
        if (lang) {
            setLanguage(lang);
        }
    }, []);

    return (
        <div key="1" className='max-w-390'>
            <Stories posts={stories} showVideos={showVideos} setShowVideos={setShowVideos} showStories={showStories} setShowStories={setShowStories} isMuted={!showStories ? true : isMuted} setIsMuted={setIsMuted}/>
        {loading ? 
            (<div key="loading">{t['Loading...']}</div>) :
        (<ul key="posts">
            {posts.filter((post) => {
                return activeHash === null ? true : (post.tags.find(tag => tag.slug === activeHash)) ? true : false;
            }).map((post) => (<li key={post.id}><Card key={post.id} post={post} answers={answers} setAnswers={setAnswers} translations={t} isMuted={isMuted} setIsMuted={setIsMuted} showStories={showStories} /></li>))}
        </ul>
        )}
        </div>
    );
  }
  