import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ReactComponent as IconCheckEmpty } from "../assets/checkbox-empty.svg";
import { ReactComponent as IconCheckCorrect } from "../assets/checkbox-correct.svg";
import { ReactComponent as IconCheckWrong } from "../assets/checkbox-wrong.svg";
import { ReactComponent as IconGraphics } from "../assets/popup-graphics.svg";
import { ReactComponent as IconMute } from "../assets/mute.svg";
import { ReactComponent as IconSound } from "../assets/sound.svg";
import { useSwipeable } from "react-swipeable";



import { register } from 'swiper/element/bundle';
register();
const answersApiUrl = `${process.env.REACT_APP_REST_API_URL}/humanrights/v1/answer`;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
        {
          rootMargin: '-10% -30% -10% -30%',
          threshold: 0,
        }
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default function Story({ setPercentage, isActive, post, item, nextItem, prevItem, translations: t, isMuted, setIsMuted, showStories }) {
  const cardRef = useRef(null);
  const [isInViewport, setIsInViewport] = useState(isActive); //useIsInViewport(cardRef);
  const videoContainerRef = useRef(null);
  const [isVideo, setIsVideo] = useState(false);
  const [count, setCount] = useState(8);
  const countRef = useRef(count);
  countRef.current = count;
  const [hasVideo, setHasVideo] = useState(post.items.filter(item => item.video).length > 0);

  // video
  useEffect(() => {
    if (cardRef.current) {
      const videoEl = cardRef.current.querySelector(' video');
      if (videoEl) {
        setIsVideo(true);
      }
      if (videoEl && !isActive) {
        videoEl.pause();
        videoEl.currentTime = 0;
        return;
      }
      
      if (videoEl && !showStories) {
        videoEl.pause();
        return;
      }

      if (videoEl && isActive) {
        videoEl.ontimeupdate = () => {
          var percentage = ( videoEl.currentTime / videoEl.duration ) * 100;
          setPercentage(percentage);
        }
        videoEl.onended = () => {
          setPercentage(0);
          nextItem();
        }
        videoEl.play();
      } else if(videoEl) {
        videoEl.currentTime = 0;
        videoEl.pause();
      }
    }

  }, [isInViewport, showStories, isActive]);

  // image timer
  useEffect(() => {
    setCount(0);
    setPercentage(0);
    if (!isVideo && isActive) {
      const timer = setInterval(() => {
        if (countRef.current === 5) {
          clearTimeout(timer);
          setPercentage(0);
          nextItem();
          //takeScreenshot();
          return;
        }

        //console.log('percentage', countRef.current * 100 / 5);
        var percentage = (countRef.current + 1) * 100 / 5;
        setPercentage(percentage);
       // console.log('timer', countRef.current + 1);
        setCount(countRef.current + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isVideo, isActive]);

  useEffect(() => {
    if ( cardRef.current) {
      const videoEl = cardRef.current.querySelector("video");
      if (videoEl) {
        videoEl.muted = isMuted;
      }
    }
  }, [isMuted]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      eventData.event.preventDefault();
      eventData.event.stopPropagation();
      nextItem();
    },
    onSwipedRight: (eventData) => {
      eventData.event.preventDefault();
      eventData.event.stopPropagation();
      prevItem();
    },
    trackMouse: true,
    preventScrollOnSwipe: true
  });

  return (<div ref={cardRef} className="relative">
    {/*post.title.rendered*/} {/*isInViewport ? 'nähtav' : 'peidus'*/}
    {post?.post_author && (
    <div className='relative z-10 -mb-42 pt-10 pl-18 pr-60' >
      <p className='flex items-center'>{post?.post_author?.image && <span className='flex items-center justify-center flex-none w-32 h-32'><img className="object-cover w-32 h-32 border border-gray-300 rounded-full aspect-square" src={post.post_author.image.url} /></span>}
      <span className={`block overflow-hidden text-xs ml-7 whitespace-nowrap ${hasVideo ? `text-white` : `text-white`} font-bold mix-blend-exclusion`}>{post?.post_author?.username || ''}</span></p>
    </div>
    )}
    <div {...swipeHandlers} className='select-none'>
    
      <div className="relative">

        {item.text_on_image && (<div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center p-20 text-stroke">{item.text_on_image}</div>)}
        {(item.image && !item.video) && (<img draggable="false" className={`${post?.post_author ? 'mt-50' : ''} object-cover aspect-square`} src={item.image.url} />)}
        {item.video &&
          (<div className='relative'><div onClick={() => {
              setIsMuted(!isMuted)
            }} dangerouslySetInnerHTML={{
            __html: `<video preload=metadata muted playsInline class="w-full min-w-370 min-h-400 bg-gray-100">
            <source src=${item.video.url} type="video/mp4"/></video>`
          }}></div>
          <div className='absolute right-0 bottom-0 cursor-pointer rounded-full bg-white p-4 w-24 h-24 mr-10 mb-10 icon' onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsMuted(!isMuted)
            }}>
            {isMuted ? <IconMute/> : <IconSound/>}
          </div>
          </div>)}
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: `${post?.post_author?.username ? `<span class="font-bold">${post.post_author.username}</span> ` : ''}${post.description}` }} className='px-12 text-sm font-normal text-white mt-18 card-description-light'></div>
  </div>)
}
