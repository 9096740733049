import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
  } from "react-router-dom";
  import { register } from 'swiper/element/bundle';
  import { ReactComponent as IconCircle } from "../assets/circle.svg";
  import { ReactComponent as IconClose } from "../assets/cross-white.svg";
  import { useSwipeable } from "react-swipeable";
  import Story from './Story';

  register();
  const answersApiUrl = `${process.env.REACT_APP_REST_API_URL}/humanrights/v1/answer`;

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  export default function StoriesView({language, posts, showVideos, setShowVideos, isMuted, setIsMuted, showStories, setShowStories, translations: t}) {
    const swiperRef = useRef(null);
    const profileSwiperRef = useRef(null);
    const videoContainerRef = useRef(null);
    const [activePost, setActivePost] = useState(null);
    const [activeItem, setActiveItem] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [isVideo, setIsVideo] = useState(false);
    const [stories, setStories] = useState([]);
    const [count, setCount] = useState(0); // image timer
    const countRef = useRef(count);
    countRef.current = count;
    const containerRef = useRef();

    // on modal close
    useEffect(() => {
      if (activePost === null) {
        setIsVideo(false);
        setCount(0);
        setActiveItem(0);
        setPercentage(0);
        setShowVideos(false);
      }
    }, [activePost, activeItem, setActivePost]);


    const nextItem = useCallback(() => {
      setIsVideo(false);
      if (activePost === null) {
        return;
      }
      if (activeItem < stories[activePost].items.length - 1) {
        setActiveItem(activeItem + 1);
      } else {
        if (activePost < stories.length - 1) {
          setActivePost(activePost + 1);
          setActiveItem(0);
        } else {
          setActivePost(null);
          setShowStories(false);
        }
      }
    }, [stories, activeItem, activePost]);

    const prevItem = useCallback(() => {
      setIsVideo(false);
      if (activePost === null) {
        return;
      }
      if (activeItem > 0) {
        setActiveItem(activeItem - 1);
      } else {
        if (activePost > 0) {
          setActivePost(activePost - 1);
          setActiveItem(0);
        }
      }
    }, [stories, activeItem, activePost]);

    useEffect(() => {
      if (showVideos === true) {
        setActiveItem(0);
        setActivePost(0);
        setShowStories(true);
        setShowVideos(false);
      }
    }, [showVideos]);

    useEffect(() => {
      const storiesByAuthor = {};
      posts.forEach(post => {
        if (post?.post_author) {
          if (!storiesByAuthor[post.post_author.username]) {
            storiesByAuthor[post.post_author.username] = {...post};
            storiesByAuthor[post.post_author.username].items = [];
          }
          post.items.forEach(item => {
            storiesByAuthor[post.post_author.username].items.push({...item, post: post});
          });
        }
      });
      const groupedStories = [];
      for (var i in storiesByAuthor) {
        if (storiesByAuthor.hasOwnProperty(i)) {
          groupedStories.push(storiesByAuthor[i]);
        }
      }
      setStories(groupedStories);
    }, [posts]);


    useEffect(() => {
      if (showVideos === true) {
        setActivePost(0);
        setActiveItem(0);
      }
    }, [showVideos]);

    /**
     * Profile swiper
     */
    useEffect(() => {
      // Object with parameters
      const params = {
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 12,
        slidesOffsetAfter: 8,
        slidesOffsetBefore: 8,
        injectStyles: [
          `::slotted(swiper-slide) { width: auto; }`
        ]
        //autoplay: {
       //   delay: 5000,
        //},
      };
      if (!profileSwiperRef.current) {
          return;
      }
      // Assign it to swiper element
      Object.assign(profileSwiperRef.current, params);

      // initialize swiper
      profileSwiperRef.current.initialize();
    }, []);


    return (<div className="block my-10">
      <swiper-container init="false" ref={profileSwiperRef}>
        {stories && stories.length > 0 && stories.filter(post => post?.post_author).map((post, index) => (
        <swiper-slide>
          <button title={post.title} onClick={() => {
            setActiveItem(0);
            setActivePost(index);
            setShowStories(true);
            }} className='relative w-72 min-h-72'>

            {post?.post_author?.image && <span className='bg-circle relative z-10 flex items-center justify-center block mx-auto w-72 h-72'><img className="object-cover border border-gray-300 rounded-full w-60 h-60 aspect-square" src={post.post_author.image.url} /></span>}
            <span className='block mt-10 overflow-hidden text-xs whitespace-nowrap text-ellipsis'>{post?.post_author?.username || ''}</span>
          </button>
        </swiper-slide>))}
      </swiper-container>

      <div className={`${activePost != null ? `fixed` : `opacity-0 absolute pointer-events-none`} top-0 bottom-0 left-0 right-0 z-30 flex items-center  bg-black`}> {/** */}
        <div className='w-full overflow-y-auto py-40 max-h-screen'>
        <div className='mx-auto max-w-390 relative'>
        <button className='absolute top-0 right-0 z-20 mt-20 mr-20' onClick={(e) => {
              setActivePost(null);
              setPercentage(0);
              setActiveItem(0);
              setShowStories(false);
            } }><IconClose/></button>
        <ul className='block'>

        {stories.map((post, index) => {
          return post.items.map(
            (item, itemIndex) => <li key={index} className={`${(activePost === index && itemIndex === activeItem) ? `` : `absolute sr-only top-0 pointer-events-none ml-[-9990px]`}`}><Story key={post.id} isActive={activePost === index && itemIndex === activeItem} nextItem={nextItem} prevItem={prevItem} post={item.post} item={item} translations={t} isMuted={!showStories ? true : isMuted} setPercentage={setPercentage} setIsMuted={setIsMuted} showStories={showStories} /></li>
          )
          })}
          </ul>

          <div className='flex gap-8 mt-20'>
            {activePost != null && stories[activePost].items.map((item, index) => (
              <div className='bg-[#28282a] flex-grow h-4 relative'>
                {index === activeItem && <div className={`absolute ${isVideo ? `` : `transition-all ${percentage === 0 ? `duration-0` : `duration-1000`} ease-linear`} left-0 top-0 h-full bg-white`} style={{width: `${percentage}%`}}></div>}
                {index < activeItem && <div className='absolute top-0 left-0 w-full h-full bg-white'></div>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>)
  }
